import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-373bb18d"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "contentDetail"
};
var _hoisted_2 = {
  class: "text"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  ref: "downFileLists"
};
var _hoisted_6 = {
  class: "do"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_DownFileList = _resolveComponent("DownFileList");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "学习记录",
    back: true
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.detail.list.title), 1), _createElementVNode("span", null, _toDisplayString(_ctx.detail.list.createDate), 1), _ctx.detail.list.richText ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "conte",
    innerHTML: _ctx.detail.list.richText
  }, null, 8, _hoisted_3)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "conte",
    innerHTML: _ctx.detail.list.content
  }, null, 8, _hoisted_4)), _createElementVNode("div", _hoisted_5, null, 512), _createElementVNode("div", _hoisted_6, [_createVNode(_component_DownFileList, {
    ref: "downFileLists",
    onDosom: _ctx.test,
    onPickTiem: _ctx.test2
  }, null, 8, ["onDosom", "onPickTiem"])])])], 64);
}